<template>
  <div>
    <van-nav-bar title="返回" left-arrow @click-left="gohome()" />
    <div class="pageError">
      <img src="../assets/image/null-data.png" class="null-img" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  name: "error",
  data() {
    return {};
  },
  mounted() {},
  methods: {
    gohome() {
      this.$router.push({
        path: "/myhome",
      });
    },
  },
};
</script>
<style scoped lang="less">
.null-img {
  width: 300px;
  object-fit: cover;
}
</style>
